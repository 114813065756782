<template>
  <Modal
    v-model="visible"
    width="800"
    @on-cancel="returnPath"
    title="选择项目">
    <div class="box-tools">
      <div style="display: inline-block;margin-right:15px;" @mouseenter="toggleSearch(true)" @mouseleave="toggleSearch(false)">
        <Select v-model="searchBy" class="table-search" :class="{on: tableSearchOn||searchMode||searchBy}">
          <Option value="">不限</Option>
          <Option :value="col.searchKey?col.searchKey:col.key" v-for="col in columns.filter((c)=>{return c.title && c.searchable})" :key="col.key">{{col.title}}</Option>
        </Select>&nbsp;
        <Input v-model="keyword" ref="tableSearch" placeholder="搜索关键字" @on-focus="toggleSearchMode" @on-blur="toggleSearchMode" class="table-search" :class="{on: tableSearchOn||searchMode||keyword}"></Input>&nbsp;
        <Button type="default" icon="ios-search" @click="search"></Button>
      </div>
      <Button type="default" icon="md-refresh" @click="refreshList">刷新</Button>
    </div>
    <div class="box-body" style="clear: both">
      <Table stripe :columns="columns" :data="dataViewPage" ref="table" @on-selection-change="selectRowChange"></Table>
      <div style="margin: 10px;overflow: hidden">
        <div style="float: right;">
          <Page :total="data.length" size="small" :page-size-opts="[10,20,50,100]" @on-change="changePage" @on-page-size-change="changePageSize" :current="pageCurrent" show-sizer show-elevator></Page>
        </div>
      </div>
    </div>
    <div slot="footer">
      <Button class="pull-left" size="large" @click="returnPath">取消</Button>
      <div style="clear:both"></div>
    </div>
  </Modal>
</template>

<script>
export default {
  props: ['show'],
  components: {
  },
  data () {
    return {
      // typeId: 0 表示全部设备， 1表示广告屏设备   2表示闸机设备
      typeId: 0,
      datatableData: [],
      projectSelected: null,
      modal_loading: false,
      modalDelete: false,
      modalDisable: false,
      modalDetail: false,
      rowSelected: false,
      rowSelectedList: [],
      pageCurrent: 1,
      pageSize: 10,
      keyword: '',
      searchBy: '',
      searchMode: false,
      tableSearchOn: false,
      columns: [
        { title: '#', key: 'Tid', sortable: true },
        {
          title: '项目名称',
          key: 'Name',
          width: 200,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.localeCompare(b, 'zh-CN')
            } else {
              return b.localeCompare(a, 'zh-CN')
            }
          }
        },
        {
          title: '项目地址',
          key: 'Address',
          width: 200,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.localeCompare(b, 'zh-CN')
            } else {
              return b.localeCompare(a, 'zh-CN')
            }
          }
        },
        {
          title: '计划开工日期',
          key: 'PlannedStartDate',
          width: 120,
          sortable: true,
          searchable: true,
          render: (h, params) => {
            return h('span', null, this.$moment(params.row.PlannedStartDate).format('YYYY-MM-DD'))
          }
        },
        {
          title: '计划竣工日期',
          key: 'PlannedCompletionDate',
          width: 120,
          sortable: true,
          searchable: true,
          render: (h, params) => {
            return h('span', null, this.$moment(params.row.PlannedCompletionDate).format('YYYY-MM-DD'))
          }
        },
        {
          title: '操作',
          key: 'action',
          // fixed: 'right',
          // width: 120,
          render: (h, params) => {
            return h('Button-group', [
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small'
                },
                style: {
                },
                on: {
                  click: () => {
                    this.getProject(params.row.Tid)
                  }
                }
              }, '选择')
            ])
          }
        }
      ],
      dataRaw: [],
      data: [],
      dataViewPage: [],
      visible: false
    }
  },
  watch: {
    'show': function (newVal) {
      if (newVal) {
        this.visible = true
      } else {
        this.visible = false
      }
    },
    'data': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageCurrent': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageSize': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize
      })
    }
  },
  mounted () {
    this.initDataTable()
  },
  methods: {
    getDate (date) {
      return date.substr(0, 10)
    },
    getProject (tid) {
      this.projectSelected = null
      this.projectSelected = this.dataRaw.filter(function (item) {
        return item.Tid === tid
      })[0]
      this.$emit('returnProject', this.projectSelected)
      this.returnPath()
    },
    initDataTable () {
      this.$Spin.show()
      this.$Api.Project.listAllProjects().then((respBody) => {
        this.$Spin.hide()
        this.dataRaw = respBody.Projects
        this.data = respBody.Projects
        this.pageSize = 10
        this.pageCurrent = 1
      })
      .catch(err => {
        this.$Spin.hide()
        this.$Notice.error({
          title: '获取用户列表失败!',
          desc: err.message
        })
      })
    },
    selectRowChange (selection) {
      if (selection.length > 0) {
        this.rowSelected = true
        this.rowSelectedList = selection
      } else {
        this.rowSelected = false
        this.rowSelectedList = []
      }
    },
    changePage (page) {
      this.pageCurrent = page
    },
    changePageSize (pageSize) {
      this.pageSize = pageSize
    },
    toggleSearchMode () {
      this.searchMode = !this.searchMode
    },
    toggleSearch (val) {
      this.tableSearchOn = val
    },
    search () {
      let cols = this.columns.filter((col) => {
        return col.searchable
      })
      this.data = this.dataRaw.filter((item) => {
        if (this.searchBy) {
          let content = this.getSearchQueryContent(item, this.searchBy)
          return content.toString().indexOf(this.keyword) >= 0
        } else {
          let found = false
          for (let i = 0; i < cols.length; i++) {
            let col = cols[i]
            let content = this.getSearchQueryContent(item, col.searchKey ? col.searchKey : col.key)
            if (content && (content.toString().indexOf(this.keyword) >= 0)) {
              found = true
            }
          }
          return found
        }
      })
      this.pageCurrent = 0
      this.pageCurrent = 1
    },
    getSearchQueryContent (item, key) {
      if (!key) {
        return ''
      }
      let qArr = []
      let qArrTemp = key.split('.')
      qArrTemp.forEach((q) => {
        if (q.indexOf(']')) {
          let subArr = q.replace(/]/g, '').split('[')
          subArr.forEach((sub) => {
            qArr.push(sub)
          })
        } else {
          qArr.push(q)
        }
      })
      let content = item
      qArr.forEach((query) => {
        if (content[query]) {
          content = content[query]
        } else {
          content = ''
        }
      })
      return content
    },
    refreshList () {
      this.initDataTable()
      this.searchBy = ''
      this.keyword = ''
      this.searchMode = false
      this.tableSearchOn = false
    },
    returnPath () {
      this.$emit('close')
      this.$Modal.remove()
    }
  }
}
</script>

<style scoped>
.box-tools {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.table-search {
  transition: width 0.3s ease-in-out;
  width: 0;
  overflow: hidden;
}
.table-search.on {
  width: 120px;
}
</style>

