<template>
  <div class="alarm-new">
    <Modal
      v-model="visible"
      title="新增预警信息"
      :mask-closable="false"
      width="600"
      @on-cancel="returnPath">
      <Form ref="formAlarmAdd" :model="alarm" :rules="ruleValidate" :label-width="120">
        <Form-item label="项目" prop="ProjectName">
          {{ alarm.ProjectName }}
          <Button type="primary" @click="showPrjSelect=true" v-if="!project||!project.Name">选择项目</Button>
        </Form-item>
        <Form-item label="预警信息" prop="Message.Content">
          <Input v-model="alarm.Message.Content" :rows="5" type="textarea" placeholder="请输入预警信息..."></Input>
        </Form-item>
        <Form-item label="附件" prop="Message.Attachments">
          <div class="upload-list-box">
            <div class="upload-list" v-for="item, idx in UploadList" :key="idx">
              <template v-if="item.status === 'finished'">
                <!--<img :src="item.url">-->
                <div class="upload-list-img" :style="{ backgroundImage: 'url(' + item.url + ')' }"></div>
                <div class="upload-list-cover">
                  <Icon type="ios-eye-outline" @click.native="handleView(item.url)"></Icon>
                  <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
                </div>
              </template>
              <template v-else>
                <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
              </template>
            </div>
            <Upload
              ref="AttachmentUpload"
              multiple
              :data="OSSParams"
              :show-upload-list="false"
              :before-upload="handleBeforeUpload"
              :on-success="handleUploadSuccess"
              :format="['jpg','jpeg','png']"
              :max-size="2048"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :action="uploadParams.host">
              <Button type="default" @click="getOSSParams" style="margin-left: 5px;"><Icon type="ios-cloud-upload-outline"></Icon>上传预警信息附件</Button>
            </Upload>
            
          </div>
        </Form-item>
      </Form>
      <div slot="footer">
        <Button class="pull-left" size="large" @click="returnPath">取消</Button>
        <Button type="primary" size="large" :loading="submitting" @click="addAlarm">提交</Button>
      </div>
    </Modal>
    <Modal title="查看图片" v-model="showPic">
      <img :src="imgUrl" v-if="showPic" style="width: 100%">
    </Modal>
    <projectSelectList :show="showPrjSelect" @close="showPrjSelect=false" @returnProject="setProject"></projectSelectList>
  </div>
</template>

<script>
import projectSelectList from './ProjectSelectList'

export default {
  props: ['project', 'show'],
  watch: {
    'show': function (newVal) {
      this.visible = newVal
      if (this.project && this.project.Tid > 0) {
        this.alarm.ProjectId = this.project.Tid
        this.alarm.ProjectName = this.project.Name
        this.$nextTick(() => {
          this.UploadList = this.$refs.AttachmentUpload.fileList
        })
      }
      // if (newVal) {
      //   this.$nextTick(() => {
      //     this.UploadList = this.$refs.AttachmentUpload.fileList
      //   })
      // }
    },
    'project': function (newVal) {
      this.alarm.ProjectId = newVal.Tid
      this.alarm.ProjectName = newVal.Name
      this.$nextTick(() => {
        this.UploadList = this.$refs.AttachmentUpload.fileList
      })
    }
  },
  components: {
    projectSelectList
  },
  data () {
    return {
      permissions: {
      },
      alarm: {
        ProjectId: null,
        ProjectName: '',
        Message: {
          Content: '',
          Attachments: ''
        },
        AttachmentImgs: []
      },
      ruleValidate: {
        ProjectName: [
          { required: true, message: '项目不能为空', trigger: 'blur' }
        ],
        'Message.Content': [
          { required: true, message: '预警信息不能为空', trigger: 'blur' }
        ]
      },
      submitEnabled: true,
      submitting: false,
      visible: false,
      showPrjSelect: false,
      uploadParams: {
        accessid: '',
        host: '',
        policy: '',
        signature: '',
        callbackbody: '',
        key: '',
        expire: 0
      },
      OSSParams: {
        name: '',
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',   // 让服务端返回200,不然，默认会返回204
        callback: '',
        signature: ''
      },
      bucket: 'toubiao',
      UploadList: [],
      imgUrl: '',
      showPic: false
    }
  },
  mounted () {
  },
  methods: {
    addAlarm () {
      this.$refs['formAlarmAdd'].validate((valid) => {
        if (valid) {
          this.alarm.Message.Attachments = this.alarm.AttachmentImgs.join(',')
          this.submitting = true
          this.submitEnabled = false
          this.$Spin.show()
          this.$Api.Project.addAlarm(this.alarm).then(() => {
            this.$Spin.hide()
            this.$Message.success('操作成功！')
            this.submitEnabled = true
            this.submitting = false
            this.$emit('postAdd', null)
            this.returnPath()
          })
          .catch(err => {
            this.$Spin.hide()
            this.submitEnabled = true
            this.submitting = false
            this.$Notice.error({
              title: '提交失败!',
              desc: err.message
            })
          })
        } else {
          this.$Message.error('表单验证失败!')
          this.submitEnabled = true
          this.submitting = false
        }
      })
    },
    returnPath () {
      this.$emit('close', null)
      this.$Modal.remove()
    },
    setProject (prj) {
      this.alarm.ProjectId = prj.Tid
      this.alarm.ProjectName = prj.Name
    },
    getOSSParams () {
      this.$Api.Aliyun.getUploadParams().then((respBody) => {
        this.uploadParams = {
          accessid: respBody.accessid,
          host: respBody.host,
          policy: respBody.policy,
          signature: respBody.signature,
          callbackbody: respBody.callbackbody,
          key: respBody.dir,
          expire: respBody.expire
        }
      })
      .catch(err => {
        this.$Notice.error({
          title: '获取上传参数失败!',
          desc: err.message
        })
      })
    },
    handleBeforeUpload (file) {
      let filename = this.uploadParams.key + randomString(10) + getSuffix(file.name)
      this.OSSParams.name = file.name
      this.OSSParams.key = filename
      this.OSSParams.policy = this.uploadParams.policy
      this.OSSParams.OSSAccessKeyId = this.uploadParams.accessid
      this.OSSParams.success_action_status = '200'   // 让服务端返回200,不然，默认会返回204
      this.OSSParams.signature = this.uploadParams.signature
    },
    handleUploadSuccess (response, file, fileList) {
      if (fileList.length > 6) {
        this.$refs.AttachmentUpload.fileList.splice(0, 1)
      }
      let filename = 'oss:' + this.OSSParams.key
      this.alarm.AttachmentImgs.push(filename)
      file.name = filename
      this.getImgUrl(file, () => {
      })
    },
    handleView (url) {
      this.imgUrl = url
      this.showPic = true
    },
    handleFormatError () {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '上传文件格式不正确，请上传 jpg 或 png 格式的图片。'
      })
    },
    handleMaxSize () {
      this.$Notice.warning({
        title: '超出文件大小限制',
        desc: '文件太大，不能超过 2M。'
      })
    },
    handleRemove (file) {
      // 从 upload 实例删除数据
      const fileList = this.$refs.AttachmentUpload.fileList
      this.$refs.AttachmentUpload.fileList.splice(fileList.indexOf(file), 1)
      const fileListModel = this.alarm.AttachmentImgs
      this.alarm.AttachmentImgs.splice(fileListModel.indexOf(file.name), 1)
    },
    getImgUrl (img, callback) {
      if (!img.FileName) {
        img.ImgUrl = require('../../assets/image.png')
      } else {
        this.$Spin.show()
        this.$Api.Aliyun.getFileUrl(img.FileName).then((respBody) => {
          this.$Spin.hide()
          this.$nextTick(function () {
            img.ImgUrl = respBody.replace('-internal', '')
          })
          if (callback) {
            this.$nextTick(function () {
              callback()
            })
          }
        })
        .catch(err => {
          this.$Spin.hide()
          this.$Notice.error({
            title: '获取附件失败!',
            desc: err.message
          })
          img.ImgUrl = require('../../assets/image.png')
        })
      }
    }
  }
}

function randomString (len) {
  len = len || 32
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  var maxPos = chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

function getSuffix (filename) {
  let pos = filename.lastIndexOf('.')
  let suffix = ''
  if (pos !== -1) {
    suffix = filename.substring(pos)
  }
  return suffix
}
</script>

<style scoped>
.upload-list-box {
  margin: 0 0 8px 0;
  padding: 10px;
  border: 2px dashed #dae9ee;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  min-height: 120px;
  display: flex;
  justify-content: center;
  position: relative;
}
.upload-list-box.invalid {
  border: 2px dashed #ffc0cb;
}
.upload-list{
    display: inline-block;
    width: 120px;
    height: 120px;
    text-align: center;
    line-height: 120px;
    border: 1px solid transparent;
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0,0,0,.2);
    margin-right: 4px;
}
.upload-list img{
    width: 100%;
    height: 100%;
}
.upload-list-img {
  background-image: url('../../assets/image.png');
  background-size: cover;
  width: 100%;
  height: 100%;
}
.upload-list-cover{
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.6);
}
.upload-list:hover .upload-list-cover{
    display: block;
}
.upload-list-cover i{
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    margin: 0 10px;
}
.upload-list-box .ivu-btn {
  display: inline-block;
  width: 120px;
  height: 120px;
  text-align: center;
  border: 1px dashed #c2c2c2;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  position: relative;
  margin-right: 4px;
  white-space:initial;
}
.upload-list-box .ivu-btn:hover {
  border: 1px dashed rgb(65,157,253);
}
.upload-list-box .ivu-btn i {
  display: block;
  font-size: 24px;
  text-align: center;
}
</style>
